@import '~react-perfect-scrollbar/dist/css/styles.css';

* {
  box-sizing: border-box;
}

html {
  MozOsxFontSmoothing: 'grayscale';
  WebkitFontSmoothing: 'antialiased';
  width: 100%;
}

html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Helvetica', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
}

:focus {
  outline: none;
}

a {
  text-decoration: none;
  color: inherit;
}

strong {
  font-weight: bold;
}

img {
  height: auto;
  max-height: 100%;
  max-width: 100%;
  width: auto;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

figure {
  margin: 0 0 16px;
  display: inline-block;
}

.iframe-container {
  overflow: hidden;
  padding-top: 56.25%;
  position: relative;
}

.iframe-container iframe {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

#nprogress .bar {
  z-index: 2000 !important;
}
